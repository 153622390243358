import React, { useState } from "react";
import "./Multipal-typing.css";


function Multipaltyping (){
    return(
      <>
      <div className="main2">
        <div className="wrapper">
            <pre> <div className="static-txt">We Develop in </div></pre>
             <ul className="dynamic-txts">
                 <li><span>React.js</span></li>
                 <li><span>Python</span></li>
                 <li><span>flutter</span></li>
                 <li><span>Iphone-ios</span></li>
                 <li><span>React Native</span></li>
                 <li><span>android Kotlin</span></li>
             </ul>
          </div> 
        </div>
     
         
</>
    );

    };





export default Multipaltyping;







































        
        