import React from 'react'
import HeroPage from './Hero-Page.js'
import Multipaltyping  from './Multipal-typing.js'
import GlowingCards  from './Glowing-Cards.js'
import Brandslider  from './Brand-slider.js'
import Footer  from './Footer.js'

const App = () => {
  return<>
< HeroPage/>
<Multipaltyping/>
<GlowingCards/>
<Brandslider/>
<Footer/>
 
  </>;
  
};

export default App; 